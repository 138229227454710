import { BaseHTMLAttributes, FC, memo } from "react";
import { SectionTitle } from "../../styles/utils/Utils";
import { ROUTES } from "../../utils/constants";
import { cssBannerTile, SectionAreaActivity, TileBanners, tileBannersCategories, tileBannersProducts } from "./Styled";
import { Banner } from "components/Banners/Banner";
import { Container } from "styles/utils/StyledGrid";
type ActivityItemsType = {
  path: string;
  imagePath: string;
};
const areaActivityItems: ActivityItemsType[] = [{
  path: `${ROUTES.catalog}?sfera=30b3584c-de2b-11e9-fa83-ac1f6b855a52`,
  imagePath: `/images/business-areas/6fb0a1e04e530c1cc2813696ad1d06fe.jpg`
}, {
  path: `${ROUTES.catalog}?sfera=47f84e08-dba7-11e9-fa83-ac1f6b855a52`,
  imagePath: `/images/business-areas/d7303dce470669ebcbac15a01b3e3e24.jpg`
}, {
  path: `${ROUTES.catalog}/hozyaystvennye-tovary`,
  imagePath: `/images/business-areas/da8c0e436dc7e71d7b1c65a617e5095a.jpg`
}, {
  path: `${ROUTES.catalog}?sfera=2e51c2fe-dba7-11e9-fa83-ac1f6b855a52`,
  imagePath: `/images/business-areas/6e3e6fcbd50512ade56e00ff2dc6af91.jpg`
}, {
  path: `${ROUTES.catalog}/aksessuary-dlya-bumajnyh-stakanov`,
  imagePath: `/images/business-areas/ddac72797b92fb6a3719b667724b8517.jpg`
}];
const areaActivityItems2: ActivityItemsType[] = [{
  path: `${ROUTES.catalog}/kanat-tross-verevka-shpagat-nit-lenta`,
  imagePath: `/images/business-areas/0f58ee19e095a461aa7e5288b4d59d1f.jpg`
}, {
  path: `${ROUTES.catalog}/eco-upakovka`,
  imagePath: `/images/business-areas/24c314a8a26e46aab81604ca100fc584.jpg`
}, {
  path: `${ROUTES.catalog}/steklotara-kryshki`,
  imagePath: `/images/business-areas/f161273b1ef72f06455ac2d894c0054b.jpg`
}];
type BusinessAreasBannersPropsType = BaseHTMLAttributes<HTMLDivElement>;
const BusinessAreasBanners: FC<BusinessAreasBannersPropsType> = memo(props => {
  return <SectionAreaActivity {...props}>
        <Container>
          <SectionTitle>Сферы деятельности</SectionTitle>
          <TileBanners className={tileBannersCategories}>
            {areaActivityItems.map((item, index) => <Banner key={index} image={{
          desktop: {
            src: item.imagePath,
            layout: "responsive",
            height: 148,
            width: 272,
            objectFit: "contain"
          }
        }} loading="lazy" url={item.path} className={cssBannerTile} withFormatUrl={false} />)}
          </TileBanners>
          <TileBanners className={tileBannersProducts}>
            {areaActivityItems2.map((item, index) => <Banner key={index} image={{
          desktop: {
            src: item.imagePath,
            layout: "responsive",
            height: 290,
            width: 464,
            objectFit: "contain"
          }
        }} loading={"lazy"} url={item.path} className={cssBannerTile} withFormatUrl={false} />)}
          </TileBanners>
        </Container>
      </SectionAreaActivity>;
});
BusinessAreasBanners.displayName = "BusinessAreasBanners";
export type { BusinessAreasBannersPropsType };
export { BusinessAreasBanners };